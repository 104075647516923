.table {
  font-size: 14px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    font-size: 14px;
  }
}
.dynamic-number span:first-child {
  margin-right: 5px;
}
.sign-in-prompt {
  display: block;
  margin: 30px auto;
  text-align: center;
}

#player-queue-section {
  // flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 280px;
}
