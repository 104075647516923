.depth-button2-container {
  position: relative;
  display: flex;
  justify-content: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  button {
    position: relative;
    background: transparent;
    border: none;
    z-index: 11;
    width: 100%;
    height: 100%;
    transition: 80ms ease;
    // outline: none;
    cursor: pointer;
    .button__content-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      //   font-weight: 600;
      letter-spacing: 1px;
      color: #ffffff;
      // font-size: 14px;
      outline: none;
      &.purple {
        background-color: var(--purpleSecondary);
      }
      &.red {
        background-color: #c9132a;
      }
      &.yellow {
        background-color: #bd9e3c;
      }
      &.transparent {
        background-color: transparent;
        border: 1px solid #ffffff;
      }
    }
    &.button--down {
      transform: translateY(3px) !important;
    }
    &:hover {
      transform: translateY(0.5px);
    }
  }
  .shadow {
    border-radius: 3px;
    position: absolute;
    width: 100%;
    // height: 56px;
    top: 4px;
    z-index: 1;
    &.purple {
      background: #593a8a;
    }
    &.red {
      background: #4a0710;
    }
    &.yellow {
      background: #816715;
    }
  }
}

button.transparent-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000057;
  width: 60px;
  height: 60px;
  box-shadow: 0 0 2px 2px #00000025;
  //   box-shadow: inset 0 0 8px 11px #0000006b, 0 0 2px 3px #00000040;
  cursor: pointer;
  border-radius: 100%;
  z-index: 11;
  border: 1px solid #ffffff67;
  transition: 110ms ease;
  &:hover {
    background-color: var(--purpleSecondary);
    box-shadow: none;
  }
  svg {
    fill: #ffffff;
    display: block;
    height: 40px;
  }
}

@media only screen and (max-width: 600px) {
  button.transparent-button {
    width: 40px;
    height: 40px;
  }
}
