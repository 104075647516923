.button-controls-container {
  position: relative;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 165px;
  animation: fadeInDown 600ms forwards ease;
  animation-delay: 90ms;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  .left-right-row {
    position: relative;
    margin: -9px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .control-button-wrapper {
      &:nth-child(1) {
        margin-right: 25px;
      }
      &:nth-child(2) {
        margin-left: 25px;
      }
    }

    svg {
      height: 30px;
    }
  }
  svg {
    width: 30px;
    margin: 0;
  }
  .control-button-wrapper {
    background-color: transparent;
    margin: 8px;

    button {
      width: 60px;
    }
  }
}
.switch-camera-button-container {
  top: -20px;
  right: 20px;
  button {
    width: 50px;
    height: 50px;
    background: #00000057;
    border-radius: 50px;
    border: 1px solid #ffffff67;
    &::after {
      display: none;
    }
  }
}

.drop-claw-button-container,
.timer-container,
.button-controls-container {
  opacity: 0;
  z-index: 1111111111111111;
  animation: fadeInDown 600ms forwards ease;
}
.button-controls-container {
  animation-delay: 200ms;
}
.timer-container {
  animation-delay: 200ms;
}
.drop-claw-button-container {
  animation-delay: 200ms;
}
//Drop Claw Button
.drop_claw_button-enter {
  position: absolute;
  transform: translateY(0);
  transform: translate(-50%, 0);
  transition: transform 500ms;
}
.drop_claw_button-enter-active {
  position: absolute;
  transform: translate(-50%, 60px);
  transition: transform 500ms;
}
.drop_claw_button-exit {
  position: absolute;
  transform: translate(-50%, 60px);
  transition: transform 500ms;
}
.drop_claw_button-exit-active {
  position: absolute;
  transform: translate(-50%, 0);
  transition: transform 500ms;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 600px) {
  .button-controls-container {
    width: 120px;
    .left-right-row svg {
      height: 20px;
    }
    svg {
      width: 20px;
    }
  }
  .switch-camera-button-container {
    top: -70px !important;
    right: 0px;
    button {
      width: 42px;
      height: 42px;
    }
  }
}
