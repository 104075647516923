.slideable-content-container {
  position: absolute;
  left: 0;
  width: 100%;
  // top: 100px;
  // transform: translateY(-50%);
}

//Horiztonal
.left_to_right-enter {
  position: absolute;
  transform: translateX(120%);
  transition: transform 500ms;
}
.left_to_right-enter-active {
  transform: translateX(0);
  // transform: translateX(0);
  transition: transform 500ms;
}
.left_to_right-exit {
  //opacity: 1;
  position: absolute;
  transform: translateX(120%);
  transition: transform 500ms;
}
.left_to_right-exit-active {
  transform: translateX(120%);
  transition: transform 500ms;
}

.right_to_left-enter {
  position: absolute;
  transform: translateX(-120%);
  transition: transform 500ms;
}
.right_to_left-enter-active {
  transform: translateX(0);
  // transform: translateX(0);
  transition: transform 500ms;
}
.right_to_left-exit {
  //opacity: 1;
  position: absolute;
  transform: translateX(-120%);
  transition: transform 500ms;
}
.right_to_left-exit-active {
  transform: translateX(-120%);
  transition: transform 500ms;
}

//Vertical
.down_to_up-enter {
  position: absolute;
  transform: translateY(100%);
  transition: transform 500ms;
}
.down_to_up-enter-active {
  position: absolute;
  transform: translateY(0);
  transition: transform 500ms;
}
.down_to_up-exit {
  position: absolute;
  transform: translateY(100%);
  transition: transform 500ms;
}
.down_to_up-exit-active {
  position: absolute;
  transform: translateY(100%);
  transition: transform 500ms;
}

.up_to_down-enter {
  position: absolute;
  transform: translateY(0);
  transition: transform 500ms;
}
.up_to_down-enter-active {
  position: absolute;
  transform: translateY(0);
  transition: transform 500ms;
}
.up_to_down-exit {
  position: absolute;
  transform: translateY(120%);
  transition: transform 500ms;
  opacity: 0;
}
.up_to_down-exit-active {
  position: absolute;
  transform: translateY(120%);
  transition: transform 500ms;
}
