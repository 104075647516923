:root {
  --gold: #bd9e3c;
  --goldSecondary: #d4bf39;
  --goldAccent: #a38934;
  --purpleAccent: #9986b7;
  --purplePrimary: #593a8a;
  --purpleSecondary: #7a61a1;
  --blackPrimary: #222;
  --blackSeconday: #191919;
  --blackAccent: #2c333a;
  --blackAccentSecondary: #4a4f55;
  --blackBorder: #272a2e;
}

.social-media-container {
  justify-content: center;
  display: flex;
  position: relative;
}

.social-media-container .social-icon-link {
  color: #fff;
  width: 24px;
  height: 24px;
  -o-transition: 165ms;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  transition: all .165s;
  display: flex;
}

.social-media-container .social-icon-link svg {
  height: 17px;
  transition: all .1s;
}

.social-media-container .social-icon-link:hover svg {
  transform: translateY(-3px);
}

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset .5s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 20px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: #0000;
}

.itFJnW_player-level-container {
  position: relative;
}

.itFJnW_player-level-container .itFJnW_level__progress {
  opacity: .8;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: -100%;
  transform: translateY(-50%);
}

.itFJnW_CircularProgressbar .itFJnW_CircularProgressbar-text {
  dominant-baseline: central;
  text-anchor: middle;
  font-size: 20px;
}

.slideable-content-container {
  width: 100%;
  position: absolute;
  left: 0;
}

.left_to_right-enter {
  transition: transform .5s;
  position: absolute;
  transform: translateX(120%);
}

.left_to_right-enter-active {
  transition: transform .5s;
  transform: translateX(0);
}

.left_to_right-exit {
  transition: transform .5s;
  position: absolute;
  transform: translateX(120%);
}

.left_to_right-exit-active {
  transition: transform .5s;
  transform: translateX(120%);
}

.right_to_left-enter {
  transition: transform .5s;
  position: absolute;
  transform: translateX(-120%);
}

.right_to_left-enter-active {
  transition: transform .5s;
  transform: translateX(0);
}

.right_to_left-exit {
  transition: transform .5s;
  position: absolute;
  transform: translateX(-120%);
}

.right_to_left-exit-active {
  transition: transform .5s;
  transform: translateX(-120%);
}

.down_to_up-enter {
  transition: transform .5s;
  position: absolute;
  transform: translateY(100%);
}

.down_to_up-enter-active {
  transition: transform .5s;
  position: absolute;
  transform: translateY(0);
}

.down_to_up-exit, .down_to_up-exit-active {
  transition: transform .5s;
  position: absolute;
  transform: translateY(100%);
}

.up_to_down-enter, .up_to_down-enter-active {
  transition: transform .5s;
  position: absolute;
  transform: translateY(0);
}

.up_to_down-exit {
  opacity: 0;
  transition: transform .5s;
  position: absolute;
  transform: translateY(120%);
}

.up_to_down-exit-active {
  transition: transform .5s;
  position: absolute;
  transform: translateY(120%);
}

.depth-button2-container {
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  display: flex;
  position: relative;
}

.depth-button2-container button {
  z-index: 11;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: none;
  border: none;
  transition: all 80ms;
  position: relative;
}

.depth-button2-container button .button__content-wrapper {
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
  color: #fff;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.depth-button2-container button .button__content-wrapper.purple {
  background-color: var(--purpleSecondary);
}

.depth-button2-container button .button__content-wrapper.red {
  background-color: #c9132a;
}

.depth-button2-container button .button__content-wrapper.yellow {
  background-color: #bd9e3c;
}

.depth-button2-container button .button__content-wrapper.transparent {
  background-color: #0000;
  border: 1px solid #fff;
}

.depth-button2-container button.button--down {
  transform: translateY(3px) !important;
}

.depth-button2-container button:hover {
  transform: translateY(.5px);
}

.depth-button2-container .shadow {
  width: 100%;
  z-index: 1;
  border-radius: 3px;
  position: absolute;
  top: 4px;
}

.depth-button2-container .shadow.purple {
  background: #593a8a;
}

.depth-button2-container .shadow.red {
  background: #4a0710;
}

.depth-button2-container .shadow.yellow {
  background: #816715;
}

button.transparent-button {
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 11;
  background: #00000057;
  border: 1px solid #ffffff67;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  transition: all .11s;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px 2px #00000025;
}

button.transparent-button:hover {
  background-color: var(--purpleSecondary);
  box-shadow: none;
}

button.transparent-button svg {
  fill: #fff;
  height: 40px;
  display: block;
}

@media only screen and (max-width: 600px) {
  button.transparent-button {
    width: 40px;
    height: 40px;
  }
}

.drop-claw-button-container #btnDrop {
  background: none;
  position: relative;
}

.drop-claw-button-container #btnDrop .button__content-wrapper {
  flex-direction: column;
  display: flex;
}

.drop-claw-button-container #btnDrop .button__content-wrapper span {
  margin-top: 5px;
  font-size: 13px;
}

.drop-claw-button-container #btnDrop .button__content-wrapper svg {
  height: 36px;
  fill: #fff;
  margin: 0;
}

.drop-claw-button-container #btnDrop .aws-btn {
  --button-default-height: 125px;
  --button-default-font-size: 16px;
  --button-default-border-radius: 10px;
  --button-horizontal-padding: 35px;
  --button-raise-level: 5px;
  --button-hover-pressure: 1;
  --transform-speed: .175s;
  --button-primary-color: var(--gold);
  --button-primary-color-dark: #816715;
  --button-primary-color-light: #fff;
  --button-primary-color-hover: var(--goldAccent);
  --button-primary-border: none;
}

@media only screen and (max-width: 600px) {
  .drop-claw-button-container #btnDrop {
    font-size: 10px;
  }

  .drop-claw-button-container #btnDrop span {
    font-size: 13px !important;
  }

  .drop-claw-button-container #btnDrop svg {
    height: 28px !important;
  }
}

.button-controls-container {
  width: 165px;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  flex-direction: column;
  align-items: center;
  animation: fadeInDown .6s 90ms forwards;
  display: flex;
  position: relative;
}

.button-controls-container .left-right-row {
  width: 100%;
  justify-content: space-between;
  margin: -9px 0;
  display: flex;
  position: relative;
}

.button-controls-container .left-right-row .control-button-wrapper:nth-child(1) {
  margin-right: 25px;
}

.button-controls-container .left-right-row .control-button-wrapper:nth-child(2) {
  margin-left: 25px;
}

.button-controls-container .left-right-row svg {
  height: 30px;
}

.button-controls-container svg {
  width: 30px;
  margin: 0;
}

.button-controls-container .control-button-wrapper {
  background-color: #0000;
  margin: 8px;
}

.button-controls-container .control-button-wrapper button {
  width: 60px;
}

.switch-camera-button-container {
  top: -20px;
  right: 20px;
}

.switch-camera-button-container button {
  width: 50px;
  height: 50px;
  background: #00000057;
  border: 1px solid #ffffff67;
  border-radius: 50px;
}

.switch-camera-button-container button:after {
  display: none;
}

.drop-claw-button-container, .timer-container, .button-controls-container {
  opacity: 0;
  z-index: 2147483647;
  animation: fadeInDown .6s forwards;
}

.button-controls-container, .timer-container, .drop-claw-button-container {
  animation-delay: .2s;
}

.drop_claw_button-enter {
  transition: transform .5s;
  position: absolute;
  transform: translateY(0);
}

.drop_claw_button-enter-active, .drop_claw_button-exit {
  transition: transform .5s;
  position: absolute;
  transform: translate(-50%, 60px);
}

.drop_claw_button-exit-active {
  transition: transform .5s;
  position: absolute;
  transform: translate(-50%);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .button-controls-container {
    width: 120px;
  }

  .button-controls-container .left-right-row svg {
    height: 20px;
  }

  .button-controls-container svg {
    width: 20px;
  }

  .switch-camera-button-container {
    right: 0;
    top: -70px !important;
  }

  .switch-camera-button-container button {
    width: 42px;
    height: 42px;
  }
}

.table {
  font-size: 14px;
}

.header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header div {
  font-size: 14px;
}

.dynamic-number span:first-child {
  margin-right: 5px;
}

.sign-in-prompt {
  text-align: center;
  margin: 30px auto;
  display: block;
}

#player-queue-section {
  flex-shrink: 0;
  flex-basis: 280px;
}

#leaderboards-podium {
  max-width: 350px;
  height: 230px;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
  margin: 80px auto 30px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

#leaderboards-podium .podium-column {
  color: #222;
  background: #f0f0f0;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#leaderboards-podium .podium-column .podium-name {
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  width: 80%;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -25px;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

#leaderboards-podium .podium-column .place-number {
  text-shadow: 0 0 5px #0003;
  color: #fff;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
}

#leaderboards-podium .podium-column.first {
  height: 100%;
  z-index: 11;
  background-color: #fff;
  overflow: visible;
  box-shadow: 0 0 5px 4px #0000000a;
}

#leaderboards-podium .podium-column.first .podium-name {
  width: 100%;
}

#leaderboards-podium .podium-column.first .place-number {
  background: #d7ad41;
  box-shadow: 0 1px 0 1px #c79c2d;
}

#leaderboards-podium .podium-column.first:after {
  width: calc(100% + 20px);
  border-radius: 8px;
  top: -2px;
  left: -10px;
}

#leaderboards-podium .podium-column.second {
  height: 80%;
  border-bottom-left-radius: 6px;
}

#leaderboards-podium .podium-column.second .place-number {
  background: #c9c8c6;
  box-shadow: 0 1px 0 1px #b1b0b0;
}

#leaderboards-podium .podium-column.second:after {
  width: calc(100% + 8px);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  right: -1px;
}

#leaderboards-podium .podium-column.third {
  height: 65%;
  border-bottom-right-radius: 6px;
}

#leaderboards-podium .podium-column.third .place-number {
  background: #c66031;
  box-shadow: 0 1px 0 1px #a74d24;
}

#leaderboards-podium .podium-column.third:after {
  width: calc(100% + 8px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  left: -1px;
}

#leaderboards-podium .podium-column:after {
  content: "";
  height: 8px;
  background-color: #cbcbcb;
  position: absolute;
  top: -2px;
}

#leaderboards-podium .podium-column .wins-number {
  margin-top: 10px;
  font-weight: bold;
}

.trophy-icon {
  position: absolute;
  top: -75px;
}

.trophy-icon svg {
  color: #d7ad41;
  height: 39px;
  display: block;
}

@media only screen and (max-width: 600px) {
  #leaderboards-screen .leaderboard-screen-content-wrapper {
    flex-flow: wrap-reverse;
    margin: 80px 10px 30px;
  }
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("inter-cyrillic-ext-300-normal.d4ba79ba.woff2") format("woff2"), url("inter-all-300-normal.224bf152.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("inter-cyrillic-300-normal.e4741c08.woff2") format("woff2"), url("inter-all-300-normal.224bf152.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("inter-greek-ext-300-normal.267a953f.woff2") format("woff2"), url("inter-all-300-normal.224bf152.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("inter-greek-300-normal.c6aa77e3.woff2") format("woff2"), url("inter-all-300-normal.224bf152.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("inter-vietnamese-300-normal.40ca893e.woff2") format("woff2"), url("inter-all-300-normal.224bf152.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("inter-latin-ext-300-normal.9d9fb5be.woff2") format("woff2"), url("inter-all-300-normal.224bf152.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("inter-latin-300-normal.2a713942.woff2") format("woff2"), url("inter-all-300-normal.224bf152.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-cyrillic-ext-400-normal.d60f3f56.woff2") format("woff2"), url("inter-all-400-normal.e4946772.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-cyrillic-400-normal.ea4d085d.woff2") format("woff2"), url("inter-all-400-normal.e4946772.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-greek-ext-400-normal.96346283.woff2") format("woff2"), url("inter-all-400-normal.e4946772.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-greek-400-normal.5ea1cb75.woff2") format("woff2"), url("inter-all-400-normal.e4946772.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-vietnamese-400-normal.7dd4a418.woff2") format("woff2"), url("inter-all-400-normal.e4946772.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-latin-ext-400-normal.ae0800a8.woff2") format("woff2"), url("inter-all-400-normal.e4946772.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-latin-400-normal.58b66fb4.woff2") format("woff2"), url("inter-all-400-normal.e4946772.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-cyrillic-ext-500-normal.b2697a08.woff2") format("woff2"), url("inter-all-500-normal.0797ebcd.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-cyrillic-500-normal.123dd93b.woff2") format("woff2"), url("inter-all-500-normal.0797ebcd.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-greek-ext-500-normal.d31d218d.woff2") format("woff2"), url("inter-all-500-normal.0797ebcd.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-greek-500-normal.01e95dd7.woff2") format("woff2"), url("inter-all-500-normal.0797ebcd.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-vietnamese-500-normal.2d92f631.woff2") format("woff2"), url("inter-all-500-normal.0797ebcd.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-latin-ext-500-normal.28e45ecd.woff2") format("woff2"), url("inter-all-500-normal.0797ebcd.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-latin-500-normal.55c60941.woff2") format("woff2"), url("inter-all-500-normal.0797ebcd.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("inter-cyrillic-ext-600-normal.d6c98d1e.woff2") format("woff2"), url("inter-all-600-normal.b5f49750.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("inter-cyrillic-600-normal.63ea5c3b.woff2") format("woff2"), url("inter-all-600-normal.b5f49750.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("inter-greek-ext-600-normal.835f161a.woff2") format("woff2"), url("inter-all-600-normal.b5f49750.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("inter-greek-600-normal.ffee7cb6.woff2") format("woff2"), url("inter-all-600-normal.b5f49750.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("inter-vietnamese-600-normal.a080788a.woff2") format("woff2"), url("inter-all-600-normal.b5f49750.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("inter-latin-ext-600-normal.084d7303.woff2") format("woff2"), url("inter-all-600-normal.b5f49750.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("inter-latin-600-normal.6f7afce8.woff2") format("woff2"), url("inter-all-600-normal.b5f49750.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-cyrillic-ext-700-normal.c6d0a0fc.woff2") format("woff2"), url("inter-all-700-normal.4588ce4b.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-cyrillic-700-normal.747f26b8.woff2") format("woff2"), url("inter-all-700-normal.4588ce4b.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-greek-ext-700-normal.86704c7a.woff2") format("woff2"), url("inter-all-700-normal.4588ce4b.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-greek-700-normal.46b41b0a.woff2") format("woff2"), url("inter-all-700-normal.4588ce4b.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-vietnamese-700-normal.2fec87ac.woff2") format("woff2"), url("inter-all-700-normal.4588ce4b.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-latin-ext-700-normal.0d569462.woff2") format("woff2"), url("inter-all-700-normal.4588ce4b.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-latin-700-normal.e96e3018.woff2") format("woff2"), url("inter-all-700-normal.4588ce4b.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("rubik-cyrillic-ext-400-normal.b66a654c.woff2") format("woff2"), url("rubik-all-400-normal.15ebc653.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("rubik-cyrillic-400-normal.3ae9f92a.woff2") format("woff2"), url("rubik-all-400-normal.15ebc653.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("rubik-hebrew-400-normal.07ba8e84.woff2") format("woff2"), url("rubik-all-400-normal.15ebc653.woff") format("woff");
  unicode-range: U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("rubik-latin-ext-400-normal.dd4a0076.woff2") format("woff2"), url("rubik-all-400-normal.15ebc653.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("rubik-latin-400-normal.a39dab74.woff2") format("woff2"), url("rubik-all-400-normal.15ebc653.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-0-400-normal.91b396ef.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+F9CA-FA0B, U+FF03-FF05, U+FF07, U+FF0A-FF0B, U+FF0D-FF19, U+FF1B, U+FF1D, U+FF20-FF5B, U+FF5D, U+FFE0-FFE3, U+FFE5-FFE6;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-2-400-normal.524581b9.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D723-D728, U+D72A-D733, U+D735-D748, U+D74A-D74F, U+D752-D753, U+D755-D757, U+D75A-D75F, U+D762-D764, U+D766-D768, U+D76A-D76B, U+D76D-D76F, U+D771-D787, U+D789-D78B, U+D78D-D78F, U+D791-D797, U+D79A, U+D79C, U+D79E-D7A3, U+F900-F909, U+F90B-F92E;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-3-400-normal.a9b9a4f3.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D679-D68B, U+D68E-D69E, U+D6A0, U+D6A2-D6A7, U+D6A9-D6C3, U+D6C6-D6C7, U+D6C9-D6CB, U+D6CD-D6D3, U+D6D5-D6D6, U+D6D8-D6E3, U+D6E5-D6E7, U+D6E9-D6FB, U+D6FD-D717, U+D719-D71F, U+D721-D722;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-4-400-normal.ac4741fb.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D5BC-D5C7, U+D5CA-D5CB, U+D5CD-D5CF, U+D5D1-D5D7, U+D5D9-D5DA, U+D5DC, U+D5DE-D5E3, U+D5E6-D5E7, U+D5E9-D5EB, U+D5ED-D5F6, U+D5F8, U+D5FA-D5FF, U+D602-D603, U+D605-D607, U+D609-D60F, U+D612-D613, U+D616-D61B, U+D61D-D637, U+D63A-D63B, U+D63D-D63F, U+D641-D647, U+D64A-D64C, U+D64E-D653, U+D656-D657, U+D659-D65B, U+D65D-D666, U+D668, U+D66A-D678;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-5-400-normal.c773c367.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D507, U+D509-D50B, U+D50D-D513, U+D515-D53B, U+D53E-D53F, U+D541-D543, U+D545-D54C, U+D54E, U+D550, U+D552-D557, U+D55A-D55B, U+D55D-D55F, U+D561-D564, U+D566-D567, U+D56A, U+D56C, U+D56E-D573, U+D576-D577, U+D579-D583, U+D585-D586, U+D58A-D5A4, U+D5A6-D5BB;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-6-400-normal.2b92b0ac.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D464-D477, U+D47A-D47B, U+D47D-D47F, U+D481-D487, U+D489-D48A, U+D48C, U+D48E-D4E7, U+D4E9-D503, U+D505-D506;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-7-400-normal.6a54f51b.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D3BF-D3C7, U+D3CA-D3CF, U+D3D1-D3EB, U+D3EE-D3EF, U+D3F1-D3F3, U+D3F5-D3FB, U+D3FD-D400, U+D402-D45B, U+D45D-D463;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-8-400-normal.f7a659d5.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D2FF, U+D302-D304, U+D306-D30B, U+D30F, U+D311-D313, U+D315-D31B, U+D31E, U+D322-D324, U+D326-D327, U+D32A-D32B, U+D32D-D32F, U+D331-D337, U+D339-D33C, U+D33E-D37B, U+D37E-D37F, U+D381-D383, U+D385-D38B, U+D38E-D390, U+D392-D397, U+D39A-D39B, U+D39D-D39F, U+D3A1-D3A7, U+D3A9-D3AA, U+D3AC, U+D3AE-D3B3, U+D3B5-D3B7, U+D3B9-D3BB, U+D3BD-D3BE;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-9-400-normal.5df7142b.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D257-D27F, U+D281-D29B, U+D29D-D29F, U+D2A1-D2AB, U+D2AD-D2B7, U+D2BA-D2BB, U+D2BD-D2BF, U+D2C1-D2C7, U+D2C9-D2EF, U+D2F2-D2F3, U+D2F5-D2F7, U+D2F9-D2FE;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-10-400-normal.f27eeb46.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D1B4, U+D1B6-D1F3, U+D1F5-D22B, U+D22E-D22F, U+D231-D233, U+D235-D23B, U+D23D-D240, U+D242-D256;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-11-400-normal.c84eea29.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D105-D12F, U+D132-D133, U+D135-D137, U+D139-D13F, U+D141-D142, U+D144, U+D146-D14B, U+D14E-D14F, U+D151-D153, U+D155-D15B, U+D15E-D187, U+D189-D19F, U+D1A2-D1A3, U+D1A5-D1A7, U+D1A9-D1AF, U+D1B2-D1B3;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-12-400-normal.bd66f9f2.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D04B-D04F, U+D051-D057, U+D059-D06B, U+D06D-D06F, U+D071-D073, U+D075-D07B, U+D07E-D0A3, U+D0A6-D0A7, U+D0A9-D0AB, U+D0AD-D0B3, U+D0B6, U+D0B8, U+D0BA-D0BF, U+D0C2-D0C3, U+D0C5-D0C7, U+D0C9-D0CF, U+D0D2, U+D0D6-D0DB, U+D0DE-D0DF, U+D0E1-D0E3, U+D0E5-D0EB, U+D0EE-D0F0, U+D0F2-D104;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-13-400-normal.579f5ec0.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CFA2-CFC3, U+CFC5-CFDF, U+CFE2-CFE3, U+CFE5-CFE7, U+CFE9-CFF4, U+CFF6-CFFB, U+CFFD-CFFF, U+D001-D003, U+D005-D017, U+D019-D033, U+D036-D037, U+D039-D03B, U+D03D-D04A;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-14-400-normal.7252d755.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CEF0-CEF3, U+CEF6, U+CEF9-CEFF, U+CF01-CF03, U+CF05-CF07, U+CF09-CF0F, U+CF11-CF12, U+CF14-CF1B, U+CF1D-CF1F, U+CF21-CF2F, U+CF31-CF53, U+CF56-CF57, U+CF59-CF5B, U+CF5D-CF63, U+CF66, U+CF68, U+CF6A-CF6F, U+CF71-CF84, U+CF86-CF8B, U+CF8D-CFA1;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-15-400-normal.db118d26.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CE3C-CE57, U+CE5A-CE5B, U+CE5D-CE5F, U+CE61-CE67, U+CE6A, U+CE6C, U+CE6E-CE73, U+CE76-CE77, U+CE79-CE7B, U+CE7D-CE83, U+CE85-CE88, U+CE8A-CE8F, U+CE91-CE93, U+CE95-CE97, U+CE99-CE9F, U+CEA2, U+CEA4-CEAB, U+CEAD-CEE3, U+CEE6-CEE7, U+CEE9-CEEB, U+CEED-CEEF;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-16-400-normal.75d47212.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CD92-CD93, U+CD96-CD97, U+CD99-CD9B, U+CD9D-CDA3, U+CDA6-CDA8, U+CDAA-CDAF, U+CDB1-CDC3, U+CDC5-CDCB, U+CDCD-CDE7, U+CDE9-CE03, U+CE05-CE1F, U+CE22-CE34, U+CE36-CE3B;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-17-400-normal.1bd332c7.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CCEF-CD07, U+CD0A-CD0B, U+CD0D-CD1A, U+CD1C, U+CD1E-CD2B, U+CD2D-CD5B, U+CD5D-CD77, U+CD79-CD91;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-18-400-normal.e5a65277.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CC3F-CC43, U+CC46-CC47, U+CC49-CC4B, U+CC4D-CC53, U+CC55-CC58, U+CC5A-CC5F, U+CC61-CC97, U+CC9A-CC9B, U+CC9D-CC9F, U+CCA1-CCA7, U+CCAA, U+CCAC, U+CCAE-CCB3, U+CCB6-CCB7, U+CCB9-CCBB, U+CCBD-CCCF, U+CCD1-CCE3, U+CCE5-CCEE;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-19-400-normal.a7bf81e9.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CB91-CBD3, U+CBD5-CBE3, U+CBE5-CC0B, U+CC0E-CC0F, U+CC11-CC13, U+CC15-CC1B, U+CC1D-CC20, U+CC23-CC27, U+CC2A-CC2B, U+CC2D, U+CC2F, U+CC31-CC37, U+CC3A, U+CC3C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-20-400-normal.3e9f282e.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CAF4-CB47, U+CB4A-CB90;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-21-400-normal.d189f600.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+CA4A-CA4B, U+CA4E-CA4F, U+CA51-CA53, U+CA55-CA5B, U+CA5D-CA60, U+CA62-CA83, U+CA85-CABB, U+CABE-CABF, U+CAC1-CAC3, U+CAC5-CACB, U+CACD-CAD0, U+CAD2, U+CAD4-CAD8, U+CADA-CAF3;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-22-400-normal.0a636d02.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C996-C997, U+C99A-C99C, U+C99E-C9BF, U+C9C2-C9C3, U+C9C5-C9C7, U+C9C9-C9CF, U+C9D2, U+C9D4, U+C9D7-C9D8, U+C9DB, U+C9DE-C9DF, U+C9E1-C9E3, U+C9E5-C9E6, U+C9E8-C9EB, U+C9EE-C9F0, U+C9F2-C9F7, U+C9F9-CA0B, U+CA0D-CA28, U+CA2A-CA49;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-23-400-normal.99d2cab6.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C8E9-C8F4, U+C8F6-C8FB, U+C8FE-C8FF, U+C901-C903, U+C905-C90B, U+C90E-C910, U+C912-C917, U+C919-C92B, U+C92D-C94F, U+C951-C953, U+C955-C96B, U+C96D-C973, U+C975-C987, U+C98A-C98B, U+C98D-C98F, U+C991-C995;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-24-400-normal.08c1e9c4.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C841-C84B, U+C84D-C86F, U+C872-C873, U+C875-C877, U+C879-C87F, U+C882-C884, U+C887-C88A, U+C88D-C8C3, U+C8C5-C8DF, U+C8E1-C8E8;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-25-400-normal.149a56a9.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C779-C77B, U+C77E-C782, U+C786, U+C78B, U+C78D, U+C78F, U+C792-C793, U+C795, U+C797, U+C799-C79F, U+C7A2, U+C7A7-C7AB, U+C7AE-C7BB, U+C7BD-C7C0, U+C7C2-C7C7, U+C7C9-C7DC, U+C7DE-C7FF, U+C802-C803, U+C805-C807, U+C809, U+C80B-C80F, U+C812, U+C814, U+C817-C81B, U+C81E-C81F, U+C821-C823, U+C825-C82E, U+C830-C837, U+C839-C83B, U+C83D-C840;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-26-400-normal.dbed39cc.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C6BB-C6BF, U+C6C2, U+C6C4, U+C6C6-C6CB, U+C6CE-C6CF, U+C6D1-C6D3, U+C6D5-C6DB, U+C6DD-C6DF, U+C6E1-C6E7, U+C6E9-C6EB, U+C6ED-C6EF, U+C6F1-C6F8, U+C6FA-C703, U+C705-C707, U+C709-C70B, U+C70D-C716, U+C718, U+C71A-C71F, U+C722-C723, U+C725-C727, U+C729-C734, U+C736-C73B, U+C73E-C73F, U+C741-C743, U+C745-C74B, U+C74E-C750, U+C752-C757, U+C759-C773, U+C776-C777;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-27-400-normal.e1220e14.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C5F5-C5FB, U+C5FE, U+C602-C605, U+C607, U+C609-C60F, U+C611-C61A, U+C61C-C623, U+C626-C627, U+C629-C62B, U+C62D, U+C62F-C632, U+C636, U+C638, U+C63A-C63F, U+C642-C643, U+C645-C647, U+C649-C652, U+C656-C65B, U+C65D-C65F, U+C661-C663, U+C665-C677, U+C679-C67B, U+C67D-C693, U+C696-C697, U+C699-C69B, U+C69D-C6A3, U+C6A6, U+C6A8, U+C6AA-C6AF, U+C6B2-C6B3, U+C6B5-C6B7, U+C6B9-C6BA;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-28-400-normal.29634b50.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C517-C527, U+C52A-C52B, U+C52D-C52F, U+C531-C538, U+C53A, U+C53C, U+C53E-C543, U+C546-C547, U+C54B, U+C54D-C552, U+C556, U+C55A-C55B, U+C55D, U+C55F, U+C562-C563, U+C565-C567, U+C569-C56F, U+C572, U+C574, U+C576-C57B, U+C57E-C57F, U+C581-C583, U+C585-C586, U+C588-C58B, U+C58E, U+C590, U+C592-C596, U+C599-C5B3, U+C5B6-C5B7, U+C5BA, U+C5BE-C5C3, U+C5CA-C5CB, U+C5CD, U+C5CF, U+C5D2-C5D3, U+C5D5-C5D7, U+C5D9-C5DF, U+C5E1-C5E2, U+C5E4, U+C5E6-C5EB, U+C5EF, U+C5F1-C5F3;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-29-400-normal.f2d059b5.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C475-C4EF, U+C4F2-C4F3, U+C4F5-C4F7, U+C4F9-C4FF, U+C502-C50B, U+C50D-C516;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-30-400-normal.fbb1c459.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C3D0-C3D7, U+C3DA-C3DB, U+C3DD-C3DE, U+C3E1-C3EC, U+C3EE-C3F3, U+C3F5-C42B, U+C42D-C463, U+C466-C474;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-31-400-normal.a6215f86.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C32B-C367, U+C36A-C36B, U+C36D-C36F, U+C371-C377, U+C37A-C37B, U+C37E-C383, U+C385-C387, U+C389-C3CF;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-32-400-normal.05b5107f.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C26A-C26B, U+C26D-C26F, U+C271-C273, U+C275-C27B, U+C27E-C287, U+C289-C28F, U+C291-C297, U+C299-C29A, U+C29C-C2A3, U+C2A5-C2A7, U+C2A9-C2AB, U+C2AD-C2B3, U+C2B6, U+C2B8, U+C2BA-C2BB, U+C2BD-C2DB, U+C2DE-C2DF, U+C2E1-C2E2, U+C2E5-C2EA, U+C2EE, U+C2F0, U+C2F2-C2F5, U+C2F7, U+C2FA-C2FB, U+C2FD-C2FF, U+C301-C307, U+C309-C30C, U+C30E-C312, U+C315-C323, U+C325-C328, U+C32A;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-33-400-normal.f58496ea.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C1BC-C1C3, U+C1C5-C1DF, U+C1E1-C1FB, U+C1FD-C203, U+C205-C20C, U+C20E, U+C210-C217, U+C21A-C21B, U+C21D-C21E, U+C221-C227, U+C229-C22A, U+C22C, U+C22E, U+C230, U+C233-C24F, U+C251-C257, U+C259-C269;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-34-400-normal.5febff3c.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C101-C11B, U+C11F, U+C121-C123, U+C125-C12B, U+C12E, U+C132-C137, U+C13A-C13B, U+C13D-C13F, U+C141-C147, U+C14A, U+C14C-C153, U+C155-C157, U+C159-C15B, U+C15D-C166, U+C169-C16F, U+C171-C177, U+C179-C18B, U+C18E-C18F, U+C191-C193, U+C195-C19B, U+C19D-C19E, U+C1A0, U+C1A2-C1A4, U+C1A6-C1BB;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-35-400-normal.8d7af7fe.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+C049-C057, U+C059-C05B, U+C05D-C05F, U+C061-C067, U+C069-C08F, U+C091-C0AB, U+C0AE-C0AF, U+C0B1-C0B3, U+C0B5, U+C0B7-C0BB, U+C0BE, U+C0C2-C0C7, U+C0CA-C0CB, U+C0CD-C0CF, U+C0D1-C0D7, U+C0D9-C0DA, U+C0DC, U+C0DE-C0E3, U+C0E5-C0EB, U+C0ED-C0F3, U+C0F6, U+C0F8, U+C0FA-C0FF;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-36-400-normal.6ca47dbf.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BFA7-BFAF, U+BFB1-BFC4, U+BFC6-BFCB, U+BFCE-BFCF, U+BFD1-BFD3, U+BFD5-BFDB, U+BFDD-C048;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-37-400-normal.952f4478.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BF07, U+BF09-BF3F, U+BF41-BF4F, U+BF52-BF54, U+BF56-BFA6;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-38-400-normal.36400475.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BE56, U+BE58, U+BE5C-BE5F, U+BE62-BE63, U+BE65-BE67, U+BE69-BE74, U+BE76-BE7B, U+BE7E-BE7F, U+BE81-BE8E, U+BE90, U+BE92-BEA7, U+BEA9-BECF, U+BED2-BED3, U+BED5-BED6, U+BED9-BEE3, U+BEE6-BF06;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-39-400-normal.840418a9.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BDB0-BDD3, U+BDD5-BDEF, U+BDF1-BE0B, U+BE0D-BE0F, U+BE11-BE13, U+BE15-BE43, U+BE46-BE47, U+BE49-BE4B, U+BE4D-BE53;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-40-400-normal.df4e8459.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BD03, U+BD06, U+BD08, U+BD0A-BD0F, U+BD11-BD22, U+BD25-BD47, U+BD49-BD58, U+BD5A-BD7F, U+BD82-BD83, U+BD85-BD87, U+BD8A-BD8F, U+BD91-BD92, U+BD94, U+BD96-BD98, U+BD9A-BDAF;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-41-400-normal.4c0679da.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BC4E-BC83, U+BC86-BC87, U+BC89-BC8B, U+BC8D-BC93, U+BC96, U+BC98, U+BC9B-BC9F, U+BCA2-BCA3, U+BCA5-BCA7, U+BCA9-BCB2, U+BCB4-BCBB, U+BCBE-BCBF, U+BCC1-BCC3, U+BCC5-BCCC, U+BCCE-BCD0, U+BCD2-BCD4, U+BCD6-BCF3, U+BCF7, U+BCF9-BCFB, U+BCFD-BD02;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-42-400-normal.982b5546.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BB90-BBA3, U+BBA5-BBAB, U+BBAD-BBBF, U+BBC1-BBF7, U+BBFA-BBFB, U+BBFD-BBFE, U+BC01-BC07, U+BC09-BC0A, U+BC0E, U+BC10, U+BC12-BC13, U+BC17, U+BC19-BC1A, U+BC1E, U+BC20-BC23, U+BC26, U+BC28, U+BC2A-BC2C, U+BC2E-BC2F, U+BC32-BC33, U+BC35-BC37, U+BC39-BC3F, U+BC41-BC42, U+BC44, U+BC46-BC48, U+BC4A-BC4D;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-43-400-normal.3f3a0567.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BAE6-BAFB, U+BAFD-BB17, U+BB19-BB33, U+BB37, U+BB39-BB3A, U+BB3D-BB43, U+BB45-BB46, U+BB48, U+BB4A-BB4F, U+BB51-BB53, U+BB55-BB57, U+BB59-BB62, U+BB64-BB8F;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-44-400-normal.3c9629cd.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+BA30-BA37, U+BA3A-BA3B, U+BA3D-BA3F, U+BA41-BA47, U+BA49-BA4A, U+BA4C, U+BA4E-BA53, U+BA56-BA57, U+BA59-BA5B, U+BA5D-BA63, U+BA65-BA66, U+BA68-BA6F, U+BA71-BA73, U+BA75-BA77, U+BA79-BA84, U+BA86, U+BA88-BAA7, U+BAAA, U+BAAD-BAAF, U+BAB1-BAB7, U+BABA, U+BABC, U+BABE-BAE5;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-45-400-normal.32f2ac9a.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B96E-B973, U+B976-B977, U+B979-B97B, U+B97D-B983, U+B986, U+B988, U+B98A-B98D, U+B98F-B9AB, U+B9AE-B9AF, U+B9B1-B9B3, U+B9B5-B9BB, U+B9BE, U+B9C0, U+B9C2-B9C7, U+B9CA-B9CB, U+B9CD, U+B9D2-B9D7, U+B9DA, U+B9DC, U+B9DF-B9E0, U+B9E2, U+B9E6-B9E7, U+B9E9-B9F3, U+B9F6, U+B9F8, U+B9FB-BA2F;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-46-400-normal.267a6f43.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B8BF-B8CB, U+B8CD-B8E0, U+B8E2-B8E7, U+B8EA-B8EB, U+B8ED-B8EF, U+B8F1-B8F7, U+B8FA, U+B8FC, U+B8FE-B903, U+B905-B917, U+B919-B91F, U+B921-B93B, U+B93D-B957, U+B95A-B95B, U+B95D-B95F, U+B961-B967, U+B969-B96C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-47-400-normal.ee14d5e8.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B80D-B80F, U+B811-B817, U+B81A, U+B81C-B823, U+B826-B827, U+B829-B82B, U+B82D-B833, U+B836, U+B83A-B83F, U+B841-B85B, U+B85E-B85F, U+B861-B863, U+B865-B86B, U+B86E, U+B870, U+B872-B8AF, U+B8B1-B8BE;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-48-400-normal.413ed356.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B74D-B75F, U+B761-B763, U+B765-B774, U+B776-B77B, U+B77E-B77F, U+B781-B783, U+B785-B78B, U+B78E, U+B792-B796, U+B79A-B79B, U+B79D-B7A7, U+B7AA, U+B7AE-B7B3, U+B7B6-B7C8, U+B7CA-B7EB, U+B7EE-B7EF, U+B7F1-B7F3, U+B7F5-B7FB, U+B7FE, U+B802-B806, U+B80A-B80B;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-49-400-normal.46063d46.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B6A7-B6AA, U+B6AC-B6B0, U+B6B2-B6EF, U+B6F1-B727, U+B72A-B72B, U+B72D-B72E, U+B731-B737, U+B739-B73A, U+B73C-B743, U+B745-B74C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-50-400-normal.afc37b4f.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B605-B60F, U+B612-B617, U+B619-B624, U+B626-B69B, U+B69E-B6A3, U+B6A5-B6A6;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-51-400-normal.1fcf7122.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B55F, U+B562-B583, U+B585-B59F, U+B5A2-B5A3, U+B5A5-B5A7, U+B5A9-B5B2, U+B5B5-B5BA, U+B5BD-B604;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-52-400-normal.0b5a81c2.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B4A5-B4B6, U+B4B8-B4BF, U+B4C1-B4C7, U+B4C9-B4DB, U+B4DE-B4DF, U+B4E1-B4E2, U+B4E5-B4EB, U+B4EE, U+B4F0, U+B4F2-B513, U+B516-B517, U+B519-B51A, U+B51D-B523, U+B526, U+B528, U+B52B-B52F, U+B532-B533, U+B535-B537, U+B539-B53F, U+B541-B544, U+B546-B54B, U+B54D-B54F, U+B551-B55B, U+B55D-B55E;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-53-400-normal.5ddd69d6.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B3F8-B3FB, U+B3FD-B40F, U+B411-B417, U+B419-B41B, U+B41D-B41F, U+B421-B427, U+B42A-B42B, U+B42D-B44F, U+B452-B453, U+B455-B457, U+B459-B45F, U+B462-B464, U+B466-B46B, U+B46D-B47F, U+B481-B4A3;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-54-400-normal.9a00ed96.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B342-B353, U+B356-B357, U+B359-B35B, U+B35D-B35E, U+B360-B363, U+B366, U+B368, U+B36A-B36D, U+B36F, U+B372-B373, U+B375-B377, U+B379-B37F, U+B381-B382, U+B384, U+B386-B38B, U+B38D-B3C3, U+B3C6-B3C7, U+B3C9-B3CA, U+B3CD-B3D3, U+B3D6, U+B3D8, U+B3DA-B3F7;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-55-400-normal.9707fd76.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B27C-B283, U+B285-B28F, U+B292-B293, U+B295-B297, U+B29A-B29F, U+B2A1-B2A4, U+B2A7-B2A9, U+B2AB, U+B2AD-B2C7, U+B2CA-B2CB, U+B2CD-B2CF, U+B2D1-B2D7, U+B2DA, U+B2DC, U+B2DE-B2E3, U+B2E7, U+B2E9-B2EA, U+B2EF-B2F3, U+B2F6, U+B2F8, U+B2FA-B2FB, U+B2FD-B2FE, U+B302-B303, U+B305-B307, U+B309-B30F, U+B312, U+B316-B31B, U+B31D-B341;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-56-400-normal.1eeb7914.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B1D6-B1E7, U+B1E9-B1FC, U+B1FE-B203, U+B206-B207, U+B209-B20B, U+B20D-B213, U+B216-B21F, U+B221-B257, U+B259-B273, U+B275-B27B;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-57-400-normal.cf082b40.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B120-B122, U+B126-B127, U+B129-B12B, U+B12D-B133, U+B136, U+B138, U+B13A-B13F, U+B142-B143, U+B145-B14F, U+B151-B153, U+B156-B157, U+B159-B177, U+B17A-B17B, U+B17D-B17F, U+B181-B187, U+B189-B18C, U+B18E-B191, U+B195-B1A7, U+B1A9-B1CB, U+B1CD-B1D5;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-58-400-normal.fb45c211.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B05F-B07B, U+B07E-B07F, U+B081-B083, U+B085-B08B, U+B08D-B097, U+B09B, U+B09D-B09F, U+B0A2-B0A7, U+B0AA, U+B0B0, U+B0B2, U+B0B6-B0B7, U+B0B9-B0BB, U+B0BD-B0C3, U+B0C6-B0C7, U+B0CA-B0CF, U+B0D1-B0DF, U+B0E1-B0E4, U+B0E6-B107, U+B10A-B10B, U+B10D-B10F, U+B111-B112, U+B114-B117, U+B119-B11A, U+B11C-B11F;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-59-400-normal.6ca69839.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+AFAC-AFB7, U+AFBA-AFBB, U+AFBD-AFBF, U+AFC1-AFC6, U+AFCA-AFCC, U+AFCE-AFD3, U+AFD5-AFE7, U+AFE9-AFEF, U+AFF1-B00B, U+B00D-B00F, U+B011-B013, U+B015-B01B, U+B01D-B027, U+B029-B043, U+B045-B047, U+B049, U+B04B, U+B04D-B052, U+B055-B056, U+B058-B05C, U+B05E;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-60-400-normal.ff4370ed.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+AF03-AF07, U+AF09-AF2B, U+AF2E-AF33, U+AF35-AF3B, U+AF3E-AF40, U+AF44-AF47, U+AF4A-AF5C, U+AF5E-AF63, U+AF65-AF7F, U+AF81-AFAB;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-61-400-normal.628e4c2f.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+AE56-AE5B, U+AE5E-AE60, U+AE62-AE64, U+AE66-AE67, U+AE69-AE6B, U+AE6D-AE83, U+AE85-AEBB, U+AEBF, U+AEC1-AEC3, U+AEC5-AECB, U+AECE, U+AED0, U+AED2-AED7, U+AED9-AEF3, U+AEF5-AF02;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-62-400-normal.b39ee375.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+AD9C-ADA3, U+ADA5-ADBF, U+ADC1-ADC3, U+ADC5-ADC7, U+ADC9-ADD2, U+ADD4-ADDB, U+ADDD-ADDF, U+ADE1-ADE3, U+ADE5-ADF7, U+ADFA-ADFB, U+ADFD-ADFF, U+AE02-AE07, U+AE0A, U+AE0C, U+AE0E-AE13, U+AE15-AE2F, U+AE31-AE33, U+AE35-AE37, U+AE39-AE3F, U+AE42, U+AE44, U+AE46-AE49, U+AE4B, U+AE4F, U+AE51-AE53, U+AE55;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-63-400-normal.196a62ea.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+ACE2-ACE3, U+ACE5-ACE6, U+ACE9-ACEF, U+ACF2, U+ACF4, U+ACF7-ACFB, U+ACFE-ACFF, U+AD01-AD03, U+AD05-AD0B, U+AD0D-AD10, U+AD12-AD1B, U+AD1D-AD33, U+AD35-AD48, U+AD4A-AD4F, U+AD51-AD6B, U+AD6E-AD6F, U+AD71-AD72, U+AD77-AD7C, U+AD7E, U+AD80, U+AD82-AD87, U+AD89-AD8B, U+AD8D-AD8F, U+AD91-AD9B;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-64-400-normal.7696494d.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+AC25-AC2C, U+AC2E, U+AC30, U+AC32-AC37, U+AC39-AC3F, U+AC41-AC4C, U+AC4E-AC6F, U+AC72-AC73, U+AC75-AC76, U+AC79-AC7F, U+AC82, U+AC84-AC88, U+AC8A-AC8B, U+AC8D-AC8F, U+AC91-AC93, U+AC95-AC9B, U+AC9D-AC9E, U+ACA1-ACA7, U+ACAB, U+ACAD-ACAF, U+ACB1-ACB7, U+ACBA-ACBB, U+ACBE-ACC0, U+ACC2-ACC3, U+ACC5-ACDF;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-65-400-normal.ce26b8b1.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+99DF, U+99ED, U+99F1, U+99FF, U+9A01, U+9A08, U+9A0E-9A0F, U+9A19, U+9A2B, U+9A30, U+9A36-9A37, U+9A40, U+9A43, U+9A45, U+9A4D, U+9A55, U+9A57, U+9A5A-9A5B, U+9A5F, U+9A62, U+9A65, U+9A69-9A6A, U+9AA8, U+9AB8, U+9AD3, U+9AE5, U+9AEE, U+9B1A, U+9B27, U+9B2A, U+9B31, U+9B3C, U+9B41-9B45, U+9B4F, U+9B54, U+9B5A, U+9B6F, U+9B8E, U+9B91, U+9B9F, U+9BAB, U+9BAE, U+9BC9, U+9BD6, U+9BE4, U+9BE8, U+9C0D, U+9C10, U+9C12, U+9C15, U+9C25, U+9C32, U+9C3B, U+9C47, U+9C49, U+9C57, U+9CE5, U+9CE7, U+9CE9, U+9CF3-9CF4, U+9CF6, U+9D09, U+9D1B, U+9D26, U+9D28, U+9D3B, U+9D51, U+9D5D, U+9D60-9D61, U+9D6C, U+9D72, U+9DA9, U+9DAF, U+9DB4, U+9DC4, U+9DD7, U+9DF2, U+9DF8-9DFA, U+9E1A, U+9E1E, U+9E75, U+9E79, U+9E7D, U+9E7F, U+9E92-9E93, U+9E97, U+9E9D, U+9E9F, U+9EA5, U+9EB4-9EB5, U+9EBB, U+9EBE, U+9EC3, U+9ECD-9ECE, U+9ED4, U+9ED8, U+9EDB-9EDC, U+9EDE, U+9EE8, U+9EF4, U+9F07-9F08, U+9F0E, U+9F13, U+9F20, U+9F3B, U+9F4A-9F4B, U+9F4E, U+9F52, U+9F5F, U+9F61, U+9F67, U+9F6A, U+9F6C, U+9F77, U+9F8D, U+9F90, U+9F95, U+9F9C, U+AC02-AC03, U+AC05-AC06, U+AC09-AC0F, U+AC17-AC18, U+AC1B, U+AC1E-AC1F, U+AC21-AC23;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-94-400-normal.57bfc140.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+3136, U+3138, U+313A-3140, U+3143-3144, U+3150, U+3152, U+3154-3156, U+3158-315B, U+315D-315F, U+3162, U+3164-318C, U+318E, U+3200-321B, U+3231, U+3239, U+3251-325A, U+3260-327B, U+327E-327F, U+328A-3290, U+3294, U+329E, U+32A5, U+3380-3384, U+3388-338B;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-95-400-normal.0bff9812.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+2F7D, U+2F7F-2F8B, U+2F8E-2F90, U+2F92-2F97, U+2F99-2FA0, U+2FA2-2FA3, U+2FA5-2FA9, U+2FAC-2FB1, U+2FB3-2FBC, U+2FC1-2FCA, U+2FCD-2FD4, U+3003, U+3012-3019, U+301C, U+301E-3020, U+3036, U+3041, U+3043, U+3045, U+3047, U+3049, U+304E, U+3050, U+3052, U+3056, U+305A, U+305C, U+305E, U+3062, U+3065, U+306C, U+3070-307D, U+3080, U+3085, U+3087, U+308E, U+3090-3091, U+30A1, U+30A5, U+30A9, U+30AE, U+30B1-30B2, U+30B4, U+30B6, U+30BC-30BE, U+30C2, U+30C5, U+30CC, U+30D2, U+30D4, U+30D8-30DD, U+30E4, U+30E6, U+30E8, U+30EE, U+30F0-30F2, U+30F4-30F6, U+3133, U+3135;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-96-400-normal.672a61c5.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+2541-254B, U+25A4-25A9, U+25B1, U+25B5, U+25B9, U+25BF, U+25C1, U+25C3, U+25C9-25CA, U+25CC, U+25CE, U+25D0-25D1, U+25E6, U+25EF, U+260F, U+261D, U+261F, U+262F, U+2660, U+2664, U+2667-2669, U+266D, U+266F, U+2716, U+271A, U+273D, U+2756, U+2776-277F, U+278A-2793, U+2963, U+2965, U+2AC5-2AC6, U+2ACB-2ACC, U+2F00, U+2F04, U+2F06, U+2F08, U+2F0A-2F0B, U+2F11-2F12, U+2F14, U+2F17-2F18, U+2F1C-2F1D, U+2F1F-2F20, U+2F23-2F26, U+2F28-2F29, U+2F2B, U+2F2D, U+2F2F-2F32, U+2F38, U+2F3C-2F40, U+2F42-2F4C, U+2F4F-2F52, U+2F54-2F58, U+2F5A-2F66, U+2F69-2F70, U+2F72-2F76, U+2F78, U+2F7A-2F7C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-97-400-normal.e5461739.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+2479-2487, U+249C-24D1, U+24D3-24D7, U+24D9-24E9, U+24EB-24F4, U+2500-2501, U+2503, U+250C-2513, U+2515-2516, U+2518-2540;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-98-400-normal.87e96360.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+215B-215E, U+2162-2169, U+2170-2179, U+2195-2199, U+21B0-21B4, U+21BC, U+21C0, U+21C4-21C5, U+21CD, U+21CF-21D4, U+21E0-21E3, U+21E6-21E9, U+2200, U+2202-2203, U+2206-2209, U+220B-220C, U+220F, U+2211, U+2213, U+221A, U+221D-2220, U+2222, U+2225-2227, U+2229-222C, U+222E, U+2234-2237, U+223D, U+2243, U+2245, U+2248, U+2250-2253, U+225A, U+2260-2262, U+2264-2267, U+226A-226B, U+226E-2273, U+2276-2277, U+2279-227B, U+2280-2287, U+228A-228B, U+2295-2297, U+22A3-22A5, U+22BB-22BC, U+22CE-22CF, U+22DA-22DB, U+22EE-22EF, U+2306, U+2312, U+2314, U+2467-2478;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-99-400-normal.76cf2749.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+81-82, U+84, U+A2-A5, U+A7-A8, U+AA, U+AC-AD, U+B1-B3, U+B6, U+B8-BA, U+BC-BE, U+C0, U+C2, U+C6-CB, U+CE-D0, U+D4, U+D8-D9, U+DB-DC, U+DE-DF, U+E6, U+EB, U+EE-F0, U+F4, U+F7-F9, U+FB, U+FE-FF, U+111, U+126-127, U+132-133, U+138, U+13F-142, U+149-14B, U+152-153, U+166-167, U+2BC, U+2C7, U+2D0, U+2D8-2D9, U+2DB-2DD, U+391-394, U+396-3A1, U+3A3-3A9, U+3B2-3B6, U+3B8, U+3BC, U+3BE-3C1, U+3C3-3C9, U+2010, U+2015-2016, U+2018-2019, U+201B, U+201F-2021, U+2025, U+2030, U+2033-2036, U+203C, U+203E, U+2042, U+2074, U+207A-207F, U+2081-2084, U+2109, U+2113, U+2116, U+2121, U+2126, U+212B, U+2153-2154;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-100-400-normal.353cc4b6.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+E8, U+2DA, U+2160, U+2194, U+3054, U+3058, U+306D, U+3086, U+308D, U+30AC, U+30BB, U+30C4, U+30CD-30CE, U+30E2, U+3132, U+3146, U+3149, U+339D, U+4E3B, U+4F0A, U+4FDD, U+4FE1, U+5409, U+540C, U+5834, U+592A-592B, U+5B9A, U+5DDE, U+5E0C, U+5E73, U+5F0F, U+60F3, U+653F, U+661F, U+662F, U+667A, U+683C, U+6B4C, U+6C11, U+767C, U+76EE, U+76F4, U+77F3, U+79D1, U+7A7A, U+7B2C, U+7D22, U+8207, U+8A00, U+8A71, U+9280, U+9580, U+958B, U+96C6, U+9762, U+98DF, U+9ED1, U+AC2D, U+ADC8, U+ADD3, U+AF48, U+B014, U+B134-B135, U+B158, U+B2AA, U+B35F, U+B6A4, U+B9CF, U+BB63, U+BD23, U+BE91, U+C29B, U+C3F4, U+C42C, U+C55C, U+C573, U+C58F, U+C78C, U+C7DD, U+C8F5, U+CAD1, U+CC48, U+CF10, U+CF20, U+D03C, U+D07D, U+D2A0, U+D30E, U+D38D, U+D3A8, U+D3C8, U+D5E5, U+D5F9, U+D6E4, U+F90A, U+FF02, U+FF1C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-101-400-normal.e7b55680.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+3B1, U+2466, U+25A1, U+25A3, U+261C, U+3008-3009, U+305B, U+305D, U+3069, U+30A7, U+30BA, U+30CF, U+30EF, U+3151, U+3157, U+4E4B, U+4E5F, U+4E8C, U+4ECA, U+4ED6, U+4F5B, U+50CF, U+5149, U+5165, U+5171, U+5229, U+529B, U+5316, U+539F, U+53F2, U+571F, U+5728, U+58EB, U+591C, U+5B78, U+5C11, U+5C55, U+5DDD, U+5E02, U+5FB7, U+60C5, U+610F, U+611F, U+6625, U+66F8, U+6797, U+679C, U+682A, U+6D2A, U+706B, U+7406, U+767B, U+76F8, U+77E5, U+7ACB, U+898B, U+8A69, U+8DEF, U+8FD1, U+901A, U+90E8, U+91CD, U+975E, U+AE14, U+AE6C, U+AEC0, U+AFC7, U+AFC9, U+B01C, U+B028, U+B308, U+B311, U+B314, U+B31C, U+B524, U+B560, U+B764, U+B920, U+B9E3, U+BD48, U+BE7D, U+C0DB, U+C231, U+C270, U+C2E3, U+C37D, U+C3ED, U+C530, U+C6A5, U+C6DC, U+C7A4, U+C954, U+C974, U+D000, U+D565, U+D667, U+D6C5, U+D79D, U+FF1E;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-102-400-normal.3f6ae231.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+131, U+2032, U+2465, U+2642, U+3048, U+3051, U+3083-3084, U+308F, U+30C0, U+30D1, U+30D3, U+30D6, U+30DF, U+30E7, U+3153, U+4E16, U+4E8B, U+4EE5, U+5206, U+52A0, U+52D5, U+53E4, U+53EF, U+54C1, U+57CE, U+597D, U+5B8C, U+5EA6, U+5F8C, U+5F97, U+6210, U+6240, U+624B, U+6728, U+6BD4, U+7236, U+7269, U+7279, U+738B, U+7528, U+7530, U+767E, U+798F, U+8005, U+8A18, U+90FD, U+91CC, U+9577, U+9593, U+98A8, U+AC20, U+ACF6, U+AD90, U+AF5D, U+AF80, U+AFCD, U+AFF0, U+B0A1, U+B0B5, U+B1FD, U+B2FC, U+B380, U+B51B, U+B584, U+B5B3, U+B8FD, U+B93C, U+B9F4, U+BB44, U+BC08, U+BC27, U+BC49, U+BE55, U+BE64, U+BFB0, U+BFC5, U+C178, U+C21F, U+C314, U+C4F1, U+C58D, U+C664, U+C698, U+C6A7, U+C6C1, U+C9ED, U+CAC0, U+CACC, U+CAD9, U+CCB5, U+CDCC, U+D0E4, U+D143, U+D320, U+D330, U+D54D, U+FF06, U+FF1F, U+FF5E;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-103-400-normal.8bf46a35.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B4, U+20A9, U+20AC, U+2190, U+24D8, U+2502, U+2514, U+2592, U+25C7-25C8, U+2663, U+3060, U+3064, U+3081, U+3088, U+30A3, U+30A6, U+30AA, U+30B5, U+30C7, U+30CA-30CB, U+30D0, U+30E3, U+30E5, U+339E, U+4E09, U+4EAC, U+4F5C, U+5167-5168, U+516C, U+51FA, U+5408, U+540D, U+591A, U+5B57, U+6211, U+65B9, U+660E, U+6642, U+6700, U+6B63, U+6E2F, U+7063, U+7532, U+793E, U+81EA, U+8272, U+82B1, U+897F, U+8ECA, U+91CE, U+AC38, U+AD76, U+AE84, U+AECC, U+B07D, U+B0B1, U+B215, U+B2A0, U+B310, U+B3D7, U+B52A, U+B618, U+B775, U+B797, U+BCD5, U+BD59, U+BE80, U+BEA8, U+BED1, U+BEE4-BEE5, U+C060, U+C2EF, U+C329, U+C3DC, U+C597, U+C5BD, U+C5E5, U+C69C, U+C9D6, U+CA29, U+CA5C, U+CA84, U+CC39, U+CC3B, U+CE89, U+CEE5, U+CF65, U+CF85, U+D058, U+D145, U+D22D, U+D325, U+D37D, U+D3AD, U+D769, U+FF0C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-104-400-normal.58212738.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+2161, U+2228, U+2299, U+2464, U+2517, U+2640, U+3042, U+304A, U+3053, U+3061, U+307F, U+3082, U+308C, U+3092, U+30A8, U+30AB, U+30AD, U+30B0, U+30B3, U+30B7, U+30C1, U+30C6, U+30C9, U+30D5, U+30D7, U+30DE, U+30E0-30E1, U+30EC-30ED, U+4E0B, U+4E0D, U+4EE3, U+53F0, U+548C, U+5B89, U+5BB6, U+5C0F, U+611B, U+6771, U+6AA2, U+6BCD, U+6C34, U+6CD5, U+6D77, U+767D, U+795E, U+8ECD, U+9999, U+9AD8, U+AC07, U+AC1A, U+AC40, U+AD0C, U+AD88, U+ADA4, U+AE01, U+AE65, U+AEBD, U+AEC4, U+AFE8, U+B139, U+B205, U+B383, U+B38C, U+B42C, U+B461, U+B55C, U+B78F, U+B8FB, U+B9F7, U+BAFC, U+BC99, U+BED8, U+BFCD, U+C0BF, U+C0F9, U+C167, U+C204, U+C20F, U+C22F, U+C258, U+C298, U+C2BC, U+C388, U+C501, U+C50C, U+C5B9, U+C5CE, U+C641, U+C648, U+C73D, U+CA50, U+CA61, U+CC4C, U+CEAC, U+D0D4, U+D5F7, U+D6D7, U+FF1A;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-105-400-normal.6b4b095d.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+2103, U+2463, U+25C6, U+25CB, U+266C, U+3001, U+300A, U+3046, U+304C-304D, U+304F, U+3055, U+3059, U+3063, U+3066-3068, U+306F, U+3089, U+30B8, U+30BF, U+314F, U+4E0A, U+570B, U+5730, U+5916, U+5929, U+5C71, U+5E74, U+5FC3, U+601D, U+6027, U+63D0, U+6709, U+6734, U+751F, U+7684, U+82F1, U+9053, U+91D1, U+97F3, U+AC2F, U+AC4D, U+ADC4, U+ADE4, U+AE41, U+AE4D-AE4E, U+AED1, U+AFB9, U+B0E0, U+B299, U+B365, U+B46C, U+B480, U+B4C8, U+B7B4, U+B819, U+B918, U+BAAB, U+BAB9, U+BE8F, U+BED7, U+C0EC, U+C19F, U+C1A5, U+C3D9, U+C464, U+C53D, U+C553, U+C570, U+C5CC, U+C633, U+C6A4, U+C7A3, U+C7A6, U+C886, U+C9D9-C9DA, U+C9EC, U+CA0C, U+CC21, U+CD1B, U+CD78, U+CDC4, U+CEF8, U+CFE4, U+D0A5, U+D0B5, U+D0EC, U+D15D, U+D188, U+D23C, U+D2AC, U+D729, U+D79B, U+FF01, U+FF08-FF09, U+FF5C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-106-400-normal.a16cfb02.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+2039-203A, U+223C, U+25B3, U+25B7, U+25BD, U+25CF, U+266A, U+3002, U+300B, U+304B, U+3057, U+305F, U+306A-306B, U+307E, U+308A-308B, U+3093, U+30A2, U+30AF, U+30B9, U+30C3, U+30C8, U+30E9-30EB, U+33A1, U+4E00, U+524D, U+5357, U+5B50, U+7121, U+884C, U+9751, U+AC94, U+AEBE, U+AECD, U+AF08, U+AF41, U+AF49, U+B010, U+B053, U+B109, U+B11B, U+B128, U+B154, U+B291, U+B2E6, U+B301, U+B385, U+B525, U+B5B4, U+B729, U+B72F, U+B738, U+B7FF, U+B837, U+B975, U+BA67, U+BB47, U+BC1F, U+BD90, U+BFD4, U+C27C, U+C324, U+C379, U+C3E0, U+C465, U+C53B, U+C58C, U+C610, U+C653, U+C6CD, U+C813, U+C82F, U+C999, U+C9E0, U+CAC4, U+CAD3, U+CBD4, U+CC10, U+CC22, U+CCB8, U+CCBC, U+CDA5, U+CE84, U+CEA3, U+CF67, U+CFE1, U+D241, U+D30D, U+D31C, U+D391, U+D401, U+D479, U+D5C9, U+D5DB, U+D649, U+D6D4;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-107-400-normal.cbbc9e50.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+B0, U+E9, U+2193, U+2462, U+260E, U+261E, U+300E-300F, U+3044, U+30A4, U+30FB-30FC, U+314D, U+5973, U+6545, U+6708, U+7537, U+AC89, U+AC9C, U+ACC1, U+AD04, U+AD75, U+AD7D, U+AE45, U+AE61, U+AF42, U+B0AB, U+B0AF, U+B0B3, U+B12C, U+B194, U+B1A8, U+B220, U+B258, U+B284, U+B2FF, U+B315, U+B371, U+B3D4-B3D5, U+B460, U+B527, U+B534, U+B810, U+B818, U+B98E, U+BA55, U+BBAC, U+BC0B, U+BC40, U+BCA1, U+BCCD, U+BD93, U+BE54, U+BE5A, U+BF08, U+BF50, U+BF55, U+BFDC, U+C0C0, U+C0D0, U+C0F4, U+C100, U+C11E, U+C170, U+C20D, U+C274, U+C290, U+C308, U+C369, U+C539, U+C587, U+C5FF, U+C6EC, U+C70C, U+C7AD, U+C7C8, U+C83C, U+C881, U+CB48, U+CC60, U+CE69, U+CE6B, U+CE75, U+CF04, U+CF08, U+CF55, U+CF70, U+CFFC, U+D0B7, U+D1A8, U+D2C8, U+D384, U+D47C, U+D48B, U+D5DD, U+D5E8, U+D720, U+D759, U+F981;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-108-400-normal.66e01f64.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+E0, U+E2, U+395, U+3B7, U+3BA, U+2460-2461, U+25A0, U+3010-3011, U+306E, U+30F3, U+314A, U+314C, U+5927, U+65B0, U+7E41, U+97D3, U+9AD4, U+AD49, U+AE0B, U+AE0D, U+AE43, U+AE5D, U+AECF, U+AF3C, U+AF64, U+AFD4, U+B080, U+B084, U+B0C5, U+B10C, U+B1E8, U+B2AC, U+B36E, U+B451, U+B515, U+B540, U+B561, U+B6AB, U+B6B1, U+B72C, U+B730, U+B744, U+B800, U+B8EC, U+B8F0, U+B904, U+B968, U+B96D, U+B987, U+B9D9, U+BB36, U+BB49, U+BC2D, U+BC43, U+BCF6, U+BD89, U+BE57, U+BE61, U+BED4, U+C090, U+C130, U+C148, U+C19C, U+C2F9, U+C36C, U+C37C, U+C384, U+C3DF, U+C575, U+C584, U+C660, U+C719, U+C816, U+CA4D, U+CA54, U+CABC, U+CB49, U+CC14, U+CFF5, U+D004, U+D038, U+D0B4, U+D0D3, U+D0E0, U+D0ED, U+D131, U+D1B0, U+D31F, U+D33D, U+D3A0, U+D3AB, U+D514, U+D584, U+D6A1, U+D6CC, U+D749, U+D760, U+D799;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-109-400-normal.ad928747.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+24, U+60, U+3B9, U+3BB, U+3BD, U+2191, U+2606, U+300C-300D, U+3131, U+3134, U+3139, U+3141-3142, U+3148, U+3161, U+3163, U+321C, U+4EBA, U+5317, U+AC31, U+AC77, U+AC9F, U+ACB9, U+ACF0-ACF1, U+ACFD, U+AD73, U+AF3D, U+B00C, U+B04A, U+B057, U+B0C4, U+B188, U+B1CC, U+B214, U+B2DB, U+B2EE, U+B304, U+B4ED, U+B518, U+B5BC, U+B625, U+B69C-B69D, U+B7AC, U+B801, U+B86C, U+B959, U+B95C, U+B985, U+BA48, U+BB58, U+BC0C, U+BC38, U+BC85, U+BC9A, U+BF40, U+C068, U+C0BD, U+C0CC, U+C12F, U+C149, U+C1E0, U+C22B, U+C22D, U+C250, U+C2FC, U+C300, U+C313, U+C370, U+C3D8, U+C557, U+C580, U+C5E3, U+C62E, U+C634, U+C6F0, U+C74D, U+C783, U+C78E, U+C796, U+C7BC, U+C92C, U+CA4C, U+CC1C, U+CC54, U+CC59, U+CE04, U+CF30, U+CFC4, U+D140, U+D321, U+D38C, U+D399, U+D54F, U+D587, U+D5D0, U+D6E8, U+D770;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-110-400-normal.5a681c7c.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D7, U+EA, U+FC, U+2192, U+25BC, U+3000, U+3137, U+3145, U+315C, U+7F8E, U+AC13, U+AC71, U+AC90, U+ACB8, U+ACE7, U+AD7F, U+AE50, U+AEF4, U+AF34, U+AFBC, U+B048, U+B09A, U+B0AD, U+B0BC, U+B113, U+B125, U+B141, U+B20C, U+B2D9, U+B2ED, U+B367, U+B369, U+B374, U+B3CB, U+B4EC, U+B611, U+B760, U+B81B, U+B834, U+B8B0, U+B8E1, U+B989, U+B9D1, U+B9E1, U+B9FA, U+BA4D, U+BA78, U+BB35, U+BB54, U+BBF9, U+BC11, U+BCB3, U+BD05, U+BD95, U+BDD4, U+BE10, U+BED0, U+BF51, U+C0D8, U+C232, U+C2B7, U+C2EB, U+C378, U+C500, U+C52C, U+C549, U+C568, U+C598, U+C5C9, U+C61B, U+C639, U+C67C, U+C717, U+C78A, U+C80A, U+C90C-C90D, U+C950, U+C9E7, U+CBE4, U+CCA9, U+CCE4, U+CDB0, U+CE78, U+CE94, U+CE98, U+CF8C, U+D018, U+D034, U+D0F1, U+D1B1, U+D280, U+D2F8, U+D338, U+D380, U+D3B4, U+D610, U+D69F, U+D6FC, U+D758;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-111-400-normal.00f40c21.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+E7, U+2022, U+203B, U+25C0, U+2605, U+2661, U+3147, U+318D, U+672C, U+8A9E, U+ACAA, U+ACBC, U+AD1C, U+AE4A, U+AE5C, U+B044, U+B054, U+B0C8-B0C9, U+B2A6, U+B2D0, U+B35C, U+B364, U+B428, U+B454, U+B465, U+B4B7, U+B4E3, U+B51C, U+B5A1, U+B784, U+B790, U+B7AB, U+B7F4, U+B82C, U+B835, U+B8E9, U+B8F8, U+B9D8, U+B9F9, U+BA5C, U+BA64, U+BABD, U+BB18, U+BB3B, U+BBFF, U+BC0D, U+BC45, U+BC97, U+BCBC, U+BE45, U+BE75, U+BE7C, U+BFCC, U+C0B6, U+C0F7, U+C14B, U+C2B4, U+C30D, U+C4F8, U+C5BB, U+C5D1, U+C5E0, U+C5EE, U+C5FD, U+C606, U+C6C5, U+C6E0, U+C708, U+C81D, U+C820, U+C824, U+C878, U+C918, U+C96C, U+C9E4, U+C9F1, U+CC2E, U+CD09, U+CEA1, U+CEF5, U+CEF7, U+CF64, U+CF69, U+CFE8, U+D035, U+D0AC, U+D230, U+D234, U+D2F4, U+D31D, U+D575, U+D578, U+D608, U+D614, U+D718, U+D751, U+D761, U+D78C, U+D790;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-112-400-normal.734dc1b9.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+2665, U+3160, U+4E2D, U+6587, U+65E5, U+AC12, U+AC14, U+AC16, U+AC81, U+AD34, U+ADE0, U+AE54, U+AEBC, U+AF2C, U+AFC0, U+AFC8, U+B04C, U+B08C, U+B099, U+B0A9, U+B0AC, U+B0AE, U+B0B8, U+B123, U+B179, U+B2E5, U+B2F7, U+B4C0, U+B531, U+B538, U+B545, U+B550, U+B5A8, U+B6F0, U+B728, U+B73B, U+B7AD, U+B7ED, U+B809, U+B864, U+B86D, U+B871, U+B9BF, U+B9F5, U+BA40, U+BA4B, U+BA58, U+BA87, U+BAAC, U+BBC0, U+BC16, U+BC34, U+BD07, U+BD99, U+BE59, U+BFD0, U+C058, U+C0E4, U+C0F5, U+C12D, U+C139, U+C228, U+C529, U+C5C7, U+C635, U+C637, U+C735, U+C77D, U+C787, U+C789, U+C8C4, U+C989, U+C98C, U+C9D0, U+C9D3, U+CC0C, U+CC99, U+CD0C, U+CD2C, U+CD98, U+CDA4, U+CE59, U+CE60, U+CE6D, U+CEA0, U+D0D0-D0D1, U+D0D5, U+D14D, U+D1A4, U+D29C, U+D2F1, U+D301, U+D39C, U+D3BC, U+D4E8, U+D540, U+D5EC, U+D640, U+D750;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-113-400-normal.fc6c892f.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+5E, U+25B2, U+25B6, U+314E, U+AC24, U+ACE1, U+ACE4, U+AE68, U+AF2D, U+B0D0, U+B0E5, U+B150, U+B155, U+B193, U+B2C9, U+B2DD, U+B3C8, U+B3FC, U+B410, U+B458, U+B4DD, U+B5A0, U+B5A4, U+B5BB, U+B7B5, U+B838, U+B840, U+B86F, U+B8F9, U+B960, U+B9E5, U+BAB8, U+BB50, U+BC1D, U+BC24-BC25, U+BCA8, U+BCBD, U+BD04, U+BD10, U+BD24, U+BE48, U+BE5B, U+BE68, U+C05C, U+C12C, U+C140, U+C15C, U+C168, U+C194, U+C219, U+C27D, U+C2A8, U+C2F1, U+C2F8, U+C368, U+C554-C555, U+C559, U+C564, U+C5D8, U+C5FC, U+C625, U+C65C, U+C6B1, U+C728, U+C794, U+C84C, U+C88C, U+C8E0, U+C8FD, U+C998, U+C9DD, U+CC0D, U+CC30, U+CEEC, U+CF13, U+CF1C, U+CF5C, U+D050, U+D07C, U+D0A8, U+D134, U+D138, U+D154, U+D1F4, U+D2BC, U+D329, U+D32C, U+D3D0, U+D3F4, U+D3FC, U+D56B, U+D5CC, U+D600-D601, U+D639, U+D6C8, U+D754, U+D765;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-114-400-normal.8b832631.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+3C-3D, U+2026, U+24D2, U+314B, U+AC11, U+ACF3, U+AD74, U+AD81, U+ADF9, U+AE34, U+AF43, U+AFB8, U+B05D, U+B07C, U+B110, U+B118, U+B17C, U+B180, U+B18D, U+B192, U+B2CC, U+B355, U+B378, U+B4A4, U+B4EF, U+B78D, U+B799, U+B7A9, U+B7FD, U+B807, U+B80C, U+B839, U+B9B4, U+B9DB, U+BA3C, U+BAB0, U+BBA4, U+BC94, U+BE4C, U+C154, U+C1C4, U+C26C, U+C2AC, U+C2ED, U+C4F4, U+C55E, U+C561, U+C571, U+C5B5, U+C5C4, U+C654-C655, U+C695, U+C6E8, U+C6F9, U+C724, U+C751, U+C775, U+C7A0, U+C7C1, U+C874, U+C880, U+C9D5, U+C9F8, U+CABD, U+CC29, U+CC2C, U+CCA8, U+CCAB, U+CCD0, U+CE21, U+CE35, U+CE7C, U+CE90, U+CEE8, U+CEF4, U+CFE0, U+D070, U+D0B9, U+D0C1, U+D0C4, U+D0C8, U+D15C, U+D1A1, U+D2C0, U+D300, U+D314, U+D3ED, U+D478, U+D480, U+D48D, U+D508, U+D53D, U+D5E4, U+D611, U+D61C, U+D68D, U+D6A8, U+D798;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-115-400-normal.20d479d3.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+23, U+25, U+5F, U+A9, U+AC08, U+AC78, U+ACA8, U+ACAC, U+ACE8, U+AD70, U+ADC0, U+ADDC, U+B137, U+B140, U+B208, U+B290, U+B2F5, U+B3C5, U+B3CC, U+B420, U+B429, U+B529, U+B530, U+B77D, U+B79C, U+B7A8, U+B7C9, U+B7F0, U+B7FC, U+B828, U+B860, U+B9AD, U+B9C1, U+B9C9, U+B9DD-B9DE, U+B9E8, U+BA38-BA39, U+BABB, U+BC00, U+BC8C, U+BCA0, U+BCA4, U+BCD1, U+BCFC, U+BD09, U+BDF0, U+BE60, U+C0AD, U+C0B4, U+C0BC, U+C190, U+C1FC, U+C220, U+C288, U+C2B9, U+C2F6, U+C528, U+C545, U+C558, U+C5BC, U+C5D4, U+C600, U+C644, U+C6C0, U+C6C3, U+C721, U+C798, U+C7A1, U+C811, U+C838, U+C871, U+C904, U+C990, U+C9DC, U+CC38, U+CC44, U+CCA0, U+CD1D, U+CD95, U+CDA9, U+CE5C, U+CF00, U+CF58, U+D150, U+D22C, U+D305, U+D328, U+D37C, U+D3F0, U+D551, U+D5A5, U+D5C8, U+D5D8, U+D63C, U+D64D, U+D669, U+D734, U+D76C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-116-400-normal.51998d14.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+26, U+2B, U+3E, U+40, U+7E, U+AC01, U+AC19, U+AC1D, U+ACA0, U+ACA9, U+ACB0, U+AD8C, U+AE09, U+AE38, U+AE40, U+AED8, U+B09C, U+B0A0, U+B108, U+B204, U+B298, U+B2D8, U+B2EB-B2EC, U+B2F4, U+B313, U+B358, U+B450, U+B4E0, U+B54C, U+B610, U+B780, U+B78C, U+B791, U+B8E8, U+B958, U+B974, U+B984, U+B9B0, U+B9BC-B9BD, U+B9CE, U+BA70, U+BBFC, U+BC0F, U+BC15, U+BC1B, U+BC31, U+BC95, U+BCC0, U+BCC4, U+BD81, U+BD88, U+C0C8, U+C11D, U+C13C, U+C158, U+C18D, U+C1A1, U+C21C, U+C4F0, U+C54A, U+C560, U+C5B8, U+C5C8, U+C5F4, U+C628, U+C62C, U+C678, U+C6CC, U+C808, U+C810, U+C885, U+C88B, U+C900, U+C988, U+C99D, U+C9C8, U+CC3D-CC3E, U+CC45, U+CD08, U+CE20, U+CEE4, U+D074, U+D0A4, U+D0DD, U+D2B9, U+D3B8, U+D3C9, U+D488, U+D544, U+D559, U+D56D, U+D588, U+D615, U+D648, U+D655, U+D658, U+D65C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-117-400-normal.208b3269.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+D, U+48, U+7C, U+AC10, U+AC15, U+AC74, U+AC80, U+AC83, U+ACC4, U+AD11, U+AD50, U+AD6D, U+ADFC, U+AE00, U+AE08, U+AE4C, U+B0A8, U+B124, U+B144, U+B178, U+B274, U+B2A5, U+B2E8, U+B2F9, U+B354, U+B370, U+B418, U+B41C, U+B4F1, U+B514, U+B798, U+B808, U+B824-B825, U+B8CC, U+B978, U+B9D0, U+B9E4, U+BAA9, U+BB3C, U+BC18, U+BC1C, U+BC30, U+BC84, U+BCF5, U+BCF8, U+BD84, U+BE0C, U+BE14, U+C0B0, U+C0C9, U+C0DD, U+C124, U+C2DD, U+C2E4, U+C2EC, U+C54C, U+C57C-C57D, U+C591, U+C5C5-C5C6, U+C5ED, U+C608, U+C640, U+C6B8, U+C6D4, U+C784, U+C7AC, U+C800-C801, U+C9C1, U+C9D1, U+CC28, U+CC98, U+CC9C, U+CCAD, U+CD5C, U+CD94, U+CD9C, U+CDE8, U+CE68, U+CF54, U+D0DC, U+D14C, U+D1A0, U+D1B5, U+D2F0, U+D30C, U+D310, U+D398, U+D45C, U+D50C, U+D53C, U+D560, U+D568, U+D589, U+D604, U+D6C4, U+D788;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-118-400-normal.a75b827b.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+39, U+49, U+4D-4E, U+A0, U+AC04, U+AC1C, U+AC70, U+AC8C, U+ACBD, U+ACF5, U+ACFC, U+AD00, U+AD6C, U+ADF8, U+B098, U+B0B4, U+B294, U+B2C8, U+B300, U+B3C4, U+B3D9, U+B4DC, U+B4E4, U+B77C, U+B7EC, U+B85D, U+B97C, U+B9C8, U+B9CC, U+BA54, U+BA74, U+BA85, U+BAA8, U+BB34, U+BB38, U+BBF8, U+BC14, U+BC29, U+BC88, U+BCF4, U+BD80, U+BE44, U+C0C1, U+C11C, U+C120, U+C131, U+C138, U+C18C, U+C218, U+C2B5, U+C2E0, U+C544, U+C548, U+C5B4, U+C5D0, U+C5EC, U+C5F0, U+C601, U+C624, U+C694, U+C6A9, U+C6B0, U+C6B4, U+C6D0, U+C704, U+C720, U+C73C, U+C740, U+C744, U+C74C, U+C758, U+C77C, U+C785, U+C788, U+C790-C791, U+C7A5, U+C804, U+C815, U+C81C, U+C870, U+C8FC, U+C911, U+C9C4, U+CCB4, U+CE58, U+CE74, U+D06C, U+D0C0, U+D130, U+D2B8, U+D3EC, U+D504, U+D55C, U+D569, U+D574, U+D638, U+D654, U+D68C;
}

@font-face {
  font-family: East Sea Dokdo;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("east-sea-dokdo-119-400-normal.2eee063f.woff2") format("woff2"), url("east-sea-dokdo-all-400-normal.7937f41d.woff") format("woff");
  unicode-range: U+20-22, U+27-2A, U+2C-38, U+3A-3B, U+3F, U+41-47, U+4A-4C, U+4F-5D, U+61-7B, U+7D, U+A1, U+AB, U+AE, U+B7, U+BB, U+BF, U+2013-2014, U+201C-201D, U+2122, U+AC00, U+ACE0, U+AE30, U+B2E4, U+B85C, U+B9AC, U+C0AC, U+C2A4, U+C2DC, U+C774, U+C778, U+C9C0, U+D558;
}

/*# sourceMappingURL=index.5f47988f.css.map */
