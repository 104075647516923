// load fonts the fun way
// @import url('https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&family=Jost:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

// reference for branding colors
:root {
  --gold: #bd9e3c;
  --goldSecondary: #d4bf39;
  --goldAccent: #a38934;
  --purpleAccent: #9986b7;
  --purplePrimary: #593a8a;
  --purpleSecondary: #7a61a1;
  --blackPrimary: #222222;
  --blackSeconday: #191919;
  --blackAccent: #2c333a;
  --blackAccentSecondary: #4a4f55;
  --blackBorder: #272a2e;
}
